import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import AboutUs from './Pages/AboutUs';
// import Home from './Pages/Home';
const Home = lazy(() => import('./Pages/Home'))
const AboutUs = lazy(() => import('./Pages/AboutUs'))
const ProductPage = lazy(() => import('./Pages/ProductPage'))
const KodeRx = lazy(() => import('./Pages/Products/KodeRx'))


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [{ path: '/', element: <Home /> }, {path: 'about', element: <AboutUs />}, {path: 'product', element: <ProductPage />}, {path: '/kodeRx', element: <KodeRx />}]
  },
], {
  basename: '/'
})



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <BrowserRouter > */}
    {/* <App /> */}
    <RouterProvider router={router} />
    {/* </BrowserRouter> */}
  </React.StrictMode>
);
