import './Loader.css'
import './App.css'
import { useState, useEffect, lazy } from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import loaderLogo from './images/KI_logo_white.png'

import ScrollReset from './Components/ScrollReset';

const Header = lazy(() => import('./Components/Header'))
// const Footer = lazy(() => import('./Components/Footer'))
const ScrollToTopButton = lazy(() => import('./Components/ScrollToTopButton'))

function App() {

  // const [showLogo, setShowLogo] = useState(true)
  // useEffect(() => {
  //   let timer = setTimeout(() => setShowLogo(false), 300)
  //   return () => clearTimeout(timer)
  // }, [])

  return (
    <>
      {/* {showLogo ?
        <div className='suspense'>
          <img className='loader-image' src={loaderLogo} />
          <div className='loader'>
          </div>
        </div>
        : */}
          <Suspense fallback={
            <div className='suspense'>
              <img className='loader-image' src={loaderLogo} alt='Company Logo' />
              <div className='loader'>
              </div>
            </div>
          }>
            <ScrollReset>
            <Header />
              <Outlet />
            <ScrollToTopButton />
            {/* <Footer /> */}
            </ScrollReset>
          </Suspense>
      {/* } */}
    </>
  );
}

export default App;
